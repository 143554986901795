import React from 'react'
import ResponsiveContainer from 'components/UI/ResponsiveContainer'
import tw from 'tailwind-styled-components'

const CarouselSlide = (props) => {
    return (
        // <ContentContainer style={{height:400,backgroundImage: `${ !props.plainBg ? 'linear-gradient(0deg, rgba(0,0,0,0.9), rgba(0,0,0,.2)), ' : '' }url('${props.backgroundImage}')`}}>
        <ContentContainer>
            <img src={props.backgroundImage} className="w-full"/>
            <ResponsiveContainer className="h-full">
                <div className="relative">
                    <Content>
                        { props.children }
                    </Content>
                </div>
            </ResponsiveContainer>
        </ContentContainer>
    );
}

const ContentContainer = tw.div`
    bg-left
    bg-cover
    bg-blend-multiply
`
const Content = tw.div`
    absolute
    left-0
    right-0
    bottom-28
    md:right-auto
    md:w-2/3
    lg:w-1/2
    xl:w-2/3
`
export default CarouselSlide;