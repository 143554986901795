import React from "react"
import { navigate } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/pagination"

import ArrowButton from "components/UI/ArrowButton"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import bgSlidePir from "components/images/slide-01-bg.png"

import CarouselSlide from "./CarouselSlide"
import { useMediaQuery } from "react-responsive"

const Slider = ({ titleHome, dataSlider }) => {
  SwiperCore.use([Autoplay])

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  })
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" })

  return (
    <section className="relative">
      <Swiper
        className="hero-slider"
        modules={[Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: true,
        }}
        pagination={{
          el: ".custom-pagination",
          dynamicBullets: true,
        }}
      >
        {dataSlider.map((slide, index) => {
          return (
            <SwiperSlide
              onClick={() => isTabletOrMobile && navigate(slide.url_link)}
              key={index}
            >
              <CarouselSlide backgroundImage={slide.image}>
                <div className="text-white">
                  <h2 className="mb-4">{slide.title}</h2>
                  <p>{slide.description}</p>
                </div>

                {!isTabletOrMobile && (
                  <div className="btn-link-slider absolute">
                    {slide.url_link && (
                      <ArrowButton
                        buttonStyle="expanded"
                        buttonClick={() => navigate(slide.url_link)}
                      >
                        {slide.labelDetil}
                      </ArrowButton>
                    )}
                  </div>
                )}
              </CarouselSlide>
            </SwiperSlide>
          )
        })}
        {/* <div className=" absolute bottom-20 w-screen z-10">
          <ResponsiveContainer>
            <div className="custom-pagination"></div>
          </ResponsiveContainer>
        </div> */}
      </Swiper>
    </section>
  )
}

export default Slider
